

.App-link {
  color: #09d3ac;
}
.invisable{
  display: none;
}
.row{
  display: flex;
  flex-direction: row;
}

.column{
  display: flex;
  flex-direction: column;
}
.noMarginHor{
    margin-top: 0;
    margin-bottom: 0;
}

.fileVak{
    padding: 15px;
    border-radius: 25px;
    width:40%;
    margin:15px auto auto 25%;
    border:2px solid black;
}

.center,.centerContent > *{
    display: flex!important;
    text-align: center;
    justify-content: center;
    align-content: center;
    margin-left: auto!important;
    margin-right: auto!important;
}
.column{
    display: flex;
    flex-direction: column;
}

.marginToElement>*{
    margin-bottom:  5px;
    margin-top: 5px;
}
.fullInput, .fullInput Input{
    display: inline-block;
    width: 100%;
}
.fileItem{
    margin: 10px auto;
}
.fileItem > .icon{

      min-width: 40px;
      height: 40px;
  }
.fileItem> p{
    text-align: left;
}
.fileItem > .closeIcon{
    margin: auto 0 auto auto;
}

.fileItem > .fileIcon{
    margin: auto 5px auto 0;
    height: 40px;
}
.fileItem > p{
    margin: auto 5px;
}
.spinner{
    animation: spinner--rotate 2s linear infinite;
}

.spinner__foreground{
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
@keyframes spinner--rotate {
    from {transform: rotate(0deg)} to {transform: rotate(360deg)}
}
.fileItemList{
    overflow:auto;
    max-height: 500px;
}

/* width */
.fileItemList::-webkit-scrollbar {
    width: 5px;
}

.fileItemList::-webkit-scrollbar:horizontal {
    width: 0px;
}
/* Track */
.fileItemList::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
.fileItemList::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}